<template>

  

</template>
<script>


import ApplicationResource from '../api/applicationResource'

const applicationResource = new ApplicationResource()

export default{



    mounted(){

        applicationResource.confirmApplication(this.$route.params.theString).then(response=>{

                if(response.data.status=='ok'){

                    this.helpers.notifySuccessMessage('დადასტურებულია/Approved')

                }else {

                    this.helpers.notifyErrorMessage('შეცდომა!/Something Went Wrong!');


                }


        })

         



    }




}



</script>
