<template>

  

</template>
<script>


 

export default{



    mounted(){ 

        this.$store.dispatch('auth/graduationAnswerSave',{chosenType:this.$route.params.type=='MANT_BUY'?'buy':'rent'}).then(()=>{
            window.location.href='https://my.ug.edu.ge';
        })  

    }




}



</script>
